import { GET, POST } from '../request';

interface IEditTradeData {
    id?: string;
    type?: string;
    multi_itemss?: { id: number, record: boolean }[]
}

interface ISummaryParams {
    start: string;
    end: string;
    export?: string;
    index?: number;
    type?: string
}

enum IOrderRule {
    'desc',
    'amount',
    'type'
}

class TradeApi {
    //上传
    static upload(data: any) {
        return POST('/v2/trade_import', data, true);
    }
    
    //保存
    static save(data: { data: any[], is_bank?: boolean | null; account_id: string }) {
        // return POST('/v2/trade_import_save',data,true);
        return POST('/v2/trade_import2', data, true);
    }
    
    static getNumber() {
        return GET(`/v2/Trade/list/`,
            {
                page: 1,
                page_size: 1,
                record: 0
            }, true);
    }
    
    static queryAgree() {
        return GET(`v2/trade_plaid_need_agree`);
    }
    
    static create(data: {
        user_id: string;
        trade_type: number;
        type: string;
        kind_id: string;
        desc: string;
        amount: string | number;
        day: string,
        manual: number
    }) {
        return POST(`/v2/Trade/operate/`,
            {
                user_id: data.user_id,
                kind_id: data.kind_id,
                // hash:0,
                day: data.day,
                desc: data.desc,
                amount: data.amount,
                type: data.type,
                fixed: true,
                trade_type: data.trade_type,
                record: true,
                manual: data.manual
            }, true);
    }
    
    static getNumberRecord() {
        return GET(`/v2/Trade/list/`,
            {
                page: 1,
                page_size: 1,
                record: 1
            }, true);
    }
    
    //查询
    static getList(data: { page: number, kind_id?: number | string, pageSize: number, order: any[] }) {
        
        
        const _order: string[] = [];
        
        data.order.forEach((item, index) => {
            if (item) {
                // 'descend' | 'ascend' | null
                if (item === 'descend') {
                    _order.push(`-${ IOrderRule[ index ] }`)
                } else if (item === 'ascend') {
                    _order.push(`${ IOrderRule[ index ] }`)
                }
            }
        })
        
        
        let order = null;
        
        
        if (_order.length > 0) {
            order = _order.join(',');
        }
        
        
        return GET(`/v2/Trade/list/`,
            {
                page: data.page,
                page_size: data.pageSize,
                kind_id: data.kind_id,
                record: 0,
                order_by: order,
                psnl: data.pageSize > 50 ? 1 : null
            }, true);
    }
    
    static exportTrade(start: string, end: string,record:number) {
        return POST(`/v2/trade_export`, { start, end,record, export: true }, true);
    }
    
    static getListRecord(data: {
        page: number,
        kind_id?: number | string,
        date?: string[],
        pageSize: number,
        order: any[]
    }) {
        
        
        console.log(data);
        
        const _order: string[] = [];
        
        data.order.forEach((item, index) => {
            if (item) {
                // 'descend' | 'ascend' | null
                if (item === 'descend') {
                    _order.push(`-${ IOrderRule[ index ] }`)
                } else if (item === 'ascend') {
                    _order.push(`${ IOrderRule[ index ] }`)
                }
            }
        })
        
        
        let order = null;
        
        
        if (_order.length > 0) {
            order = _order.join(',');
        }
        
        
        const _data: any = {
            page: data.page,
            page_size: data.pageSize,
            kind_id: data.kind_id,
            record: 1,
            order_by: order,
            psnl: data.pageSize > 50 ? 1 : null
        };
        
        if (data.date) {
            _data.day__gte = data.date[ 0 ];
            _data.day__lt = data.date[ 1 ];
        }
        
        
        console.log(_data);
        
        return GET(`/v2/Trade/list/`, _data, true);
    }
    
    
    //编辑
    static editData(data: IEditTradeData | IEditTradeData[]) {
        return POST(`/v2/Trade/operate/`, data, true);
    }
    
    //编辑
    static delData(data: { id: string[] | number[] }) {
        return POST(`/v2/Trade/delete/`, data, true);
    }
    
    //获取汇总
    static getSummary(data: ISummaryParams) {
        return POST('/v2/trade_summary', data, true);
    }
    
    
    static askSingle(data: { trade_id: string; user_desc: string }) {
        return POST('/v2/ask_single', data);
        
    }
}

export default TradeApi;
